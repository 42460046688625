<template>
    <div class="qrcode-box" id="qrcode-box">
        <title-nav :title="title"/>
        <div class="qrcode">
            <div class="row">
              <div class="img">
                <img :src="qrcodeUrl" alt="" width="300">
              </div>
            </div>
            <div class="button-group">
              <div class="button" @click="handleClick('website')">生成专属平台二维码</div>
              <div class="button" @click="handleClick('vip')">生成专属开通会员二维码</div>
              <div class="button" @click="handleClick('partner')">生成专属开通合伙人二维码</div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
// const seo = require('../../../config/index')
import QRCode from 'qrcode'
import { queryDomainByUid  } from '@/api/api'

export default {
  name: 'QRCode',
  components: {
    TitleNav,
  },
  created(){
  },
  mounted(){
    this.getData()
    this.timerC = setTimeout(()=>{
      this.handleClick('website')
    },0)
  },
  destroyed(){
    clearTimeout(this.timer)
    clearTimeout(this.timerC)
  },
  data:()=>{
    return {
        qrcodeUrl: "",
        title: '营销二维码',
        myWebUrl:'',
        promo_code:'',
        isLogin: localStorage.getItem('token') ? true : false,
    }
  },
  methods:{
    getData(){
      let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
      this.promo_code = JSON.parse(localStorage.getItem('userInfo'))[0].promotion_code
      queryDomainByUid({ uid: uidTemp }).then(res=>{
        this.myWebUrl = 'http://' + res.data.data[0].url
      })
    },
    handleClick(type){
      this.qrcodeUrl = ""
      let urlTemp = ''
      this.timer = setTimeout(()=>{
        if(type === 'website'){
          this.title = '平台二维码'
          urlTemp = this.myWebUrl + '?promo_code=' + this.promo_code
        } else if(type === 'vip'){
          this.title = '开通会员二维码'
          urlTemp = this.myWebUrl + '/activesvip' + '?promo_code=' + this.promo_code
        } else if(type === 'partner'){
          this.title = '开通合伙人二维码'
          urlTemp = this.myWebUrl + '/partner' + '?promo_code=' + this.promo_code
        }
        this.generateQRCode(urlTemp)
      },100)
    },
    generateQRCode(text){
      // 设置qrcode的参数
      const qrCodeText = text
      const qrCodeOptions = {
        width: 300,
        height: 300
      }
      let me = this
      // 生成qrcode
      QRCode.toDataURL(qrCodeText, qrCodeOptions, function (err, url) {
        if (!err) {
          me.qrcodeUrl = url // 将qrcode保存为data属性
        } else {
          console.log(err)
        }
      })
    }
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.qrcode-box {
    min-height: calc(100vh - 5px);
    background: #fff;
    padding: 0 0 5px;
    .qrcode {
      margin: 20px 20px 0;
      .row {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        .img {
          height: 300px;
          background: #f3f3f3;
        }
      }
      .button-group {
        display: flex;
        flex-wrap: wrap;
      }
      .button {
          margin: 10px auto;
          border: 1px solid #ec745b;
          background: #ec745b;
          color: #fff;
          width: 65%;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          border-radius: 30px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }
}
</style>